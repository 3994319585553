import {ref, reactive, toRefs, computed,watch,defineComponent, onMounted, getCurrentInstance,provide} from 'vue';
const BuyRentCarCardHelper = defineComponent({
    name:'SaleRentCarCard',
    title:'买车租车',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance();
        const utils=proxy.utils;
        provide('EditTableOwner', proxy);
        provide('uploadOwner', proxy);
        let dataObj=reactive({
            formRef:null,
            disabled:false,
            refMap:new Map(),
            showUpload:false,
            //卡片传给dialog的初始化参数
            compParams: {
                hsDetails: true,
                details:['buyCarDetail','rentCarDetail'],
                modelPath: "/buyRentCar"
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                flag: [
                    {required: true, message: "请选择类别", trigger: "blur" }
                ],
                name: [
                    {required: true, message: "请输入名称", trigger: "blur" }
                ],
                type: [
                    {required: true, message: "请输入车辆能源类型", trigger: "blur" }
                ]
            }
        })
        onMounted(()=>{
        })
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params) => {
                if(params && 'flag'==params.comboId){
                     return [{value:'0',label:'买车'},{value:'1',label:'租车'}]
                }
            }
        })
        //明细表格初始化参数
        const cardDetailParam=computed(()=>{
            return (detailType) => {
                return {
                    detailParam: {
                        title:'rentCarDetail'==detailType?'租车':'买车',
                        readOnly:dataObj.disabled,
                        canAdd:false,
                        canPage: false,
                        queryParam: {
                            detailType: detailType
                        },
                        toolBar: [
                            {
                                id: "selfExport",
                                title: "导出",
                                type: "primary",
                                icon: "el-icon-zoom-in",
                                clickEvent: 'gridExportHandler',
                                param: detailType
                            }
                        ],
                        modelMethod: "/buyRentCar/detail?t="+Math.random()*1000
                    }
                }
            }
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=(newValue,selectId)=>{
            if('flag'==selectId){}
        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(data,addOrLoad,cardEngine)=>{
            if('/load'==addOrLoad){
                dataObj.showUpload=true;
                dataObj.form.flag=JSON.parse(data.data.flag);
                utils.$$lghdUtils.loadUploadFiles({dataObj:dataObj,proxy:proxy,uploadType:'buyRent_clzp',files:data.data.clzpList});
            }
        }
        //行开始编辑事件回调方法，点击某一行编辑或新增一行都会调用该方法。区别：新增行的时候不会传入options,options为undifine，编辑的时候options为{row: row, column: column}
        const beganEdit=async(detailType,options)=>{
            //todo:如果明细中有cascader，而且每行的cascader数据可能不同，那么可以在这里再次请求后台，重新根据条件构造该行cascader数据
            return true;
        }
        //结束编辑行的时候，为该行指定列设置显示文本，方便不是编辑状态的情况下显示
        const beforeEndEdit=(detailType, row, index)=>{

            return true;
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            //验证明细表格数据是否合法
            //let saleRentCarDetailData=dataObj.refMap.get('saleRentCarDetail').getDetailData();
            //saleRentCarDetailData.forEach((row,index)=>{
            //    if(row.F_SY_NUM>row.F_NUM){
            //        utils.$$tools.warning({message:'第'+(index+1)+"行本次出库数量不能大于库存数量"});
            //        return false;
            //    }
            //})
            return true;
        }
        //明细表格加载完毕事件
        //const gridLoaded=(detailType,res)=>{
        //    res.rows.forEach((row)=> {
        //        row.F_D_FIELD9 = parseInt(row.F_D_FIELD9);
        //    });
        //}
        const startHandler=async()=>{
            utils.$$lghdUtils.startHandler({id:dataObj.form.id,proxy:proxy,url:dataObj.compParams.modelPath + "/custom"});
        }
        const stopHandler=async()=>{
            utils.$$lghdUtils.stopHandler({id:dataObj.form.id,proxy:proxy,url:dataObj.compParams.modelPath + "/custom"});
        }
        const buildUploadInitParams=computed(()=>{
            return (params) => {
                return {
                    formId:dataObj.form.id,
                    saveType:1,
                    listType:'picture-card',
                    showFileList:true,
                    showInDialog:true,
                    accept:'.jpg,.jpeg,.png,.JPG,.JPEG,.PBG',
                    uploadModule:'car',
                    limitNum:1,
                    uploadType:params.uploadType
                }
            }
        })
        //-----------------------------upload---------------------------
        //上传的时候，增加参数传入到后台
        const buildUploadParams=(formData,params,uploadInst)=>{
            if((uploadInst.uploadParams.saveType)==0){
                //不采用第三方文件服务器，formData才有append方法，否则formData就是一个普通的对象，没有append方法
                formData.append('belongMaxId',dataObj.form.id);
            }else{
                formData.belongMaxId=dataObj.form.id;
            }
        }
        //上传成功之后，需要把本次上传的图片文件追加到上传控件文件数组中，
        const afterResult=(res,uploadInst)=>{
            utils.$$lghdUtils.dealAfterUpload({res:res,uploadInst:uploadInst});
        }
        //jsonData只保留fields里面有的列
        const formatJson=(fields, jsonData)=>{
            return jsonData.map(rowData => fields.map(field => rowData[field]))
        }
        const gridExportHandler=(detailType)=>{
            let tbInst=dataObj.refMap.get(detailType).getTbInst();
            const data = formatJson(tbInst.tbCols.fields, dataObj.refMap.get(detailType).getDetailData());//处理之后的tbData
            let labels=tbInst.tbCols.labels;
            let title='租车明细';
            if('buyCarDetail'==detailType)title='买车明细';
            //导出数据核心就下面一行代码
            proxy.excelUtils(labels, data, title);
        }
        return{
            ...toRefs(dataObj),comboSelect,selectOnChange,cardDetailParam,beganEdit,beforeEndEdit,beforeOpen,beforeSaveHandler,startHandler,stopHandler,
            buildUploadInitParams,buildUploadParams,afterResult,gridExportHandler
         //,gridLoaded
        }
    }
});
export default BuyRentCarCardHelper;