<template>
    <div class="SaleRentCar">
        <el-form ref="formRef" :model="form" :rules="rules" label-width="150px">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="类别" prop="flag">
                        <el-select :ref="el=>refMap.set('flag',el)" filterable v-model="form.flag" multiple="true" clearable placeholder="请选择类别(可多选)" style="width: 100%" :disabled="disabled" @change="(val)=>{selectOnChange(val,'flag')}">
                            <el-option v-for="item in comboSelect({comboId:'flag'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="名称" prop="name">
                        <el-input v-model="form.name" placeholder="请输入名称" maxlength="25" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="车辆能源类型" prop="type">
                        <el-input v-model="form.type" placeholder="请输入车辆能源类型" maxlength="25" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="买车价" prop="buyPrice">
                        <el-input v-model="form.buyPrice" placeholder="请输入买车价" maxlength="20" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="租车价" prop="rentPrice">
                        <el-input v-model="form.rentPrice" placeholder="请输入租车价" maxlength="20" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div v-show="showUpload" style="margin-top: 20px;margin-bottom: 5px;">
            <el-row>
                <el-col :span="2">车辆照片</el-col>
                <el-col :span="10">
                    <Upload :="buildUploadInitParams({uploadType:'buyRent_clzp'})" :ref="el=>refMap.set('buyRent_clzp',el)"></Upload>
                </el-col>
            </el-row>
        </div>
        <el-tabs>
            <el-tab-pane label="租车">
                <EditTable :ref="el=>refMap.set('rentCarDetail',el)" v-bind="cardDetailParam('rentCarDetail')">
                    <template v-slot:tbCols>
                        <el-table-column label="名称" prop="F_NAME"/>
                        <el-table-column label="手机号" prop="F_PHONE"/>
                        <el-table-column label="预约时间" prop="F_TIME"/>
                    </template>
                </EditTable>
            </el-tab-pane>
            <el-tab-pane label="买车">
                <EditTable :ref="el=>refMap.set('buyCarDetail',el)" v-bind="cardDetailParam('buyCarDetail')">
                    <template v-slot:tbCols>
                        <el-table-column label="名称" prop="F_NAME"/>
                        <el-table-column label="手机号" prop="F_PHONE"/>
                        <el-table-column label="预约时间" prop="F_TIME"/>
                    </template>
                </EditTable>
            </el-tab-pane>
        </el-tabs>

    </div>
</template>


<script>
    import BuyRentCarCardHelper from "./BuyRentCarCardHelper.js";
    export default BuyRentCarCardHelper;
</script>

<style scoped>
    .SaleRentCar{
        width: 100%;
    }
</style>
